<template>
  <div class="container-404">
    <div class="content">
      <img
        src="https://web-data.zmlearn.com/image/xyB2YWZSH9Qcspr19zR4JL/404.9ed13bbe.svg"
        alt=""
      />
      <span>
        <i>404</i>
        <em>抱歉你访问的页面不存在</em>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.container-404 {
  width: 100vw;
  height: 100vh;
}
.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 30px;
  }
  span {
    display: flex;
    flex-direction: column;
    i {
      font-size: 90px;
      line-height: 90px;
      font-weight: bold;
      color: #000;
      margin-bottom: 30px;
    }
    em {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
